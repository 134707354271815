<template>
  <!-- TODO: Vuetify -->
  <v-card width="100%">
    <v-card-title>
      Übersicht: Kunden
      <v-spacer></v-spacer>
      <v-btn
        outlined
        @click="refreshData"
        :disabled="disabled"
        id="refresh-btn"
      >
        <icon-refresh />
      </v-btn>
      <new-customer @reload-customers="loadCustomers" />
      <edit-customer
        :dialog="edit_dialog"
        :customer="customer_to_edit"
        @close-edit-dialog="closeDialog"
      />
    </v-card-title>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      fixed-header
      :headers="headers"
      :items="customers"
      :search="search"
      :items-per-page="15"
      :footer-props="{
        'items-per-page-text': 'Artikel pro Seite',
        'items-per-page-options': [15, 25, 50, -1]
      }"
      @click:row="editCustomer"
    >
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import NewCustomer from "@/components/NewCustomer";
import IconRefresh from "@/components/icons/IconRefresh";
import EditCustomer from "@/components/EditCustomer";

export default {
  name: "Customers",
  components: { IconRefresh, NewCustomer, EditCustomer },
  data() {
    return {
      edit_dialog: false,
      customers: [],
      customer_to_edit: {},
      disabled: false,
      search: "",
      headers: [
        { text: "Kundennummer", value: "customernumber" },
        { text: "Firma", value: "company" },
        { text: "Vorname", value: "firstname" },
        { text: "Nachname", value: "lastname" },
        {
          text: "Kundengruppe",
          value: "customergroup"
        },
        { text: "Anzahl Anfragen", value: "requests.length" }
      ]
    };
  },
  methods: {
    refreshData() {
      this.disabled = true;
      this.loadCustomers();
      this.$emit("rehydrate data");
      setTimeout(() => {
        this.disabled = false;
      }, 3000);
    },
    editCustomer(e) {
      this.edit_dialog = true;
      console.log(e);
      this.customer_to_edit = e;
    },
    closeDialog() {
      this.edit_dialog = false;
    },
    loadCustomers() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/customers.json`)
        .then(response => {
          this.customers = response.data;
        });
    }
  },
  mounted() {
    this.loadCustomers();
  }
};
</script>
