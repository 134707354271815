<template>
  <!-- TODO: Vuetify -->
  <v-row justify="end">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <form
        id="edit_customer_form"
        name="edit_customer_form"
        @submit.prevent="submit"
        netlify
        class="bg-white p-4"
      >
        <h2>{{ edited_customer.name }}</h2>
        <label
          >Kundennummer *
          <input
            aria-required="true"
            required
            type="number"
            v-model="edited_customer.customernumber"
          />
        </label>
        <label>
          Firma *
          <input type="text" required v-model="edited_customer.company" />
        </label>
        <label>
          Kundengruppe *
          <select required v-model="edited_customer.customergroup">
            <option
              v-bind:key="item"
              v-bind:value="item"
              v-for="item in customergroups"
              >{{ item }}</option
            >
          </select>
        </label>

        <label>
          Anrede *
          <select required v-model="edited_customer.salutation">
            <option
              v-bind:key="salutation"
              v-bind:value="salutation"
              v-for="salutation in salutations"
              >{{ salutation }}</option
            >
          </select>
        </label>
        <label>
          Titel
          <input type="text" v-model="edited_customer.title" />
        </label>
        <label>
          Vorname *
          <input type="text" required v-model="edited_customer.firstname" />
        </label>
        <label>
          Nachname *
          <input type="text" required v-model="edited_customer.lastname" />
        </label>
        <label>
          E-Mail Adresse *
          <input type="text" required v-model="edited_customer.email" />
        </label>
        <label>
          Umsatzsteuer-Id
          <input type="text" v-model="edited_customer.vatid" />
        </label>
        <small>* Felder müssen ausgefüllt werden</small>

        <div class="flex justify-end">
          <v-btn color="red" outlined @click="$emit('close-edit-dialog')">
            Abbrechen
          </v-btn>
          <v-btn :disabled="!valid" color="success" class="ml-4" type="submit">
            Speichern
          </v-btn>
        </div>
      </form>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    valid: true,
    customergroups: ["Shop Kunde", "Gries Händler", "Gries Endkunde"],
    salutations: ["Herr", "Frau"],
    edited_customer: {}
  }),
  created() {
    this.edited_customer = this.customer;
  },
  methods: {
    submit() {
      axios
        .put(
          `${process.env.VUE_APP_API_URL}/customers/${this.customer.customernumber}`,
          this.edited_customer
        )
        .then(() => {
          this.dialog = false;
          this.$emit("reload-customers");
        })
        .catch(err => console.error(err));
    }
  },
  props: {
    customer: Object,
    dialog: Boolean
  }
};
</script>
